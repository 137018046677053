@import 'styles/mixin.scss';
@import 'styles/variables.scss';

.container {
	display: flex;
	flex-direction: column;
	background-color: $main-bg;
	margin: auto;
	width: 480px;
	padding: 48px;
}

.logo {
	width: 158px;
	margin: 0 0 40px auto;
}

.lock {
	width: 16px;
	margin-bottom: 18px;
}

.title {
	@include galano-grotesque-light();
	color: $text-color;
	font-size: 32px;
	line-height: 1.25;
	margin-bottom: 32px;
}

.inputEmailContainer {
	margin-bottom: 32px;
}

.inputPasswordContainer {
	margin-bottom: 19px;
}

.inputRememberContainer {
	margin-bottom: 27px;
}

.input {
	height: 3.2rem;
	font-size: 1.6rem;
}

.errors {
	display: block;
	margin-bottom: 10px;
	color: $error;
}
