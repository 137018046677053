$bg: #1B1225;
$main-bg: #2B2B2B;
$main-sidebar-bg: #404040;

$text-color: #fff;
$sub-text: #BABABA;

$home-text: #5E5E5D;

$btn-disabled: #5E5E5D;

$error: #F07D3C;

$input-bg-dark: #121212;
$modal-bg: rgba(0, 0, 0, .4);
$color-1: #F6F6F6;
$color-2: rgba(136, 136, 136, 0.25);
$color-3: #3C8FF0;
$color-4: #9EA2AB;
$color-5: #353535;
