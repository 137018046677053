@import './variables';

.react-datepicker {
	border-color: $sub-text;
	//border: none;
	background-color: $main-sidebar-bg;
	color: $sub-text;

	&-wrapper {
		width: 100%;

		input {
			border: solid 1px transparent;
			background-color: $main-bg;
			color: $sub-text;
			font-size: 1.2rem;
			line-height: 1.4rem;
			display: block;
			width: 100%;
			height: 2.4rem;
			padding: 0 .4rem;
			outline: none;
		}
	}

	&__header {
		background-color: $main-bg;
		//border: none;
		border-bottom-color: $sub-text;
	}

	&__current-month,
	&-time__header,
	&-year-header {
		color: $text-color;
	}

	&__day-name,
	&__day,
	&__time-name {
		color: $sub-text;
	}

	&__day,
	&__month-text,
	&__quarter-text {

		&:hover {
			background-color: $main-bg;
		}
	}

	&__day--selected,
	&__day--in-selecting-range,
	&__day--in-range,
	&__month-text--selected,
	&__month-text--in-selecting-range,
	&__month-text--in-range,
	&__quarter-text--selected,
	&__quarter-text--in-selecting-range,
	&__quarter-text--in-range {
		background-color: $main-bg;
	}

	&__time-container {

		.react-datepicker__time {
			background-color: $main-sidebar-bg;

			&-container {
				border-left-color: $sub-text;
			}
		}
	}

	&__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
		background-color: $main-bg;
	}

	&__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
		background-color: $main-bg;
		color: $sub-text;
	}

	&__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
		right: 90px;
	}
}
