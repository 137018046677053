@mixin galano-grotesque-light() {
	font-family: 'Galano Grotesque', sans-serif;
	font-style: normal;
	font-weight: 300;
}

@mixin roboto-condensed-regular() {
	font-family: 'Roboto Condensed', sans-serif;
	font-style: normal;
	font-weight: normal;
}

@mixin absoluteCenter() {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate( -50%, -50% );
}

@mixin absoluteCenterX() {
	position: absolute;
	left: 50%;
	transform: translateX( -50% );
}

@mixin absoluteCenterY() {
	position: absolute;
	top: 50%;
	transform: translateY( -50% );
}

@mixin text-hidden() {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
