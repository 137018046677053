@import "mixin";

:root {
  font-size: 62.5%;
  @include roboto-condensed-regular();

  --ck-spacing-small: .8rem;
  --ck-spacing-tiny: 0;
  --ck-color-toolbar-background: #404040;
  --ck-color-panel-background: #404040;
  --ck-color-panel-border: #404040;
  --ck-color-toolbar-border: #404040;
  --ck-color-base-foreground: #404040;
  --ck-border-radius: 0;
  --ck-icon-size: 1.6rem;
  --ck-ui-component-min-height: 2.4rem;
  --ck-color-button-on-background: #2B2B2B;
  --ck-color-button-default-hover-background: #2B2B2B;
  --ck-color-button-on-hover-background: #2B2B2B;
  --ck-color-text: #BEBEBE;
  --ck-color-link-default: #3C8FF0;
  --ck-color-input-background: #2B2B2B;
  --ck-color-input-border: #2B2B2B;

  overflow: hidden;

  body {
    @include roboto-condensed-regular();
    // Body font size 16px
    font-size: 1.6rem;

    .__react_component_tooltip .multi-line {
      text-align: left;
    }

    .react-confirm-alert-overlay {
      background-color: rgba(255,255,255,.5);
    }

    .ps__rail-x,
    .ps__rail-y {
      z-index: 2;
    }

    .ck {
      @include roboto-condensed-regular();
      font-size: 1.2rem;
      line-height: 1.4rem;
      box-shadow: none!important;

      &-focused {
        border-color: transparent!important;
      }

      &.ck-balloon-panel {
        border: none;
      }

      a {
        color: #3C8FF0;
      }

      i {
        font-style: italic;
      }

      strong {
        font-weight: bold;
      }

      ul, ol {
        padding-left: 20px;
        margin: 1rem 0;
      }

      ol {
        list-style: decimal;
      }

      ul {
        list-style: disc;
      }
    }

    .react-tabs {

      &__tab {
        border: none;
        color: #BEBEBE;
        font-size: 1.2rem;
        line-height: 1.4rem;
        padding: .8rem;

        &--selected {
          border-radius: 0;
          background-color: #2B2B2B;
        }

        &-list {
          border: none;
          margin-bottom: .8rem;
        }
      }
    }

    .fullscreen {
      height: 100%;
    }
  }
}
